import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/wolfymaster.com/wolfymaster.com/src/templates/subpageTemplate.js";
import { Row, Col, Button } from 'react-bootstrap';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row style={{
      paddingBottom: "20px"
    }} mdxType="Row">
    <Col mdxType="Col">
        <Button className="float-right" size="md" variant="outline-dark" href="/" mdxType="Button">Download PDF</Button>
    </Col>
    </Row>
    <div className="float-right">Feb 2019 to Oct 2019</div>
    <h2>{`Pernix`}</h2>
    <h5>{`Software Engineer`}</h5>
    <hr></hr>
    <p>{`Development of proprietary attribution application technology. Enables customers to control their own attribution logic
to decide who gets paid for what. Built and launched MVP to market`}</p>
    <ul>
      <li parentName="ul">{`Built on top of AWS infrastructure using Lambda, SQS, SNS, RDS, Code Pipeline, CloudWatch`}</li>
      <li parentName="ul">{`Serverless Framework to handle infrastructure as code and automated deployments`}</li>
      <li parentName="ul">{`Golang / Node / Graphql`}</li>
      <li parentName="ul">{`Continuous monitoring by way of cloudwatch and slackops`}</li>
    </ul>
    <hr style={{
      margin: "30px auto",
      border: "3px dotted #A74C43"
    }} />
    <div className="float-right">Oct 2016 to Feb 2019</div>
    <h2>{`PartnerCentric`}</h2>
    <h5>{`Software Engineer`}</h5>
    <hr></hr>
    <p>{`Lead development of all business-critical systems including development of an in-house CRM and automated reporting system.
These systems assisted the operations and sales teams for one of the leading companies in the performance marketing space.
Strong focus on scalability, automation, reporting and monitoring of systems.`}</p>
    <ul>
      <li parentName="ul">{`Systems included a monolith PHP application and modern serverless application on AWS lambda written in Golang and APIs in NodeJS.`}</li>
      <li parentName="ul">{`AWS lambda, SQS, SNS, RDS`}</li>
    </ul>
    <hr style={{
      margin: "30px auto",
      border: "3px dotted #A74C43"
    }} />
    <div className="float-right">Aug 2014 to Oct 2016</div>
    <h2>{`Epic Web Studios`}</h2>
    <h5>{`Web Developer`}</h5>
    <hr></hr>
    <p>{`Design and develop client websites and web applications using the most current and robust practices. Working as a member
of the development team toward successful completion and execution of client projects. Troubleshooting and interfacing
with client issues and questions. Remain on the cutting edge of developing technologies. Build epic stuff!`}</p>
    <ul>
      <li parentName="ul">{`Development of an in-house content management system that allowed customers to maintain their websites own their own. LAMP stack, server administration`}</li>
      <li parentName="ul">{`Managed a portfolio of approx. 20 accounts`}</li>
    </ul>
    <hr style={{
      margin: "30px auto",
      border: "3px dotted #A74C43"
    }} />
    <div className="float-right">May 2015 to Present</div>
    <h2>{`Tech Uncensored, LLC`}</h2>
    <h5>{`Freelance / Consulting`}</h5>
    <hr></hr>
    <p>{`Co-host a technology podcast and YouTube channel to help individuals get started with a career in software development.
Students I have mentored have gone on to earn mid-level to senior positions within 1-3 years.
Primarily Nodejs & React development`}</p>
    <hr style={{
      margin: "30px auto",
      border: "3px dotted #A74C43"
    }} />
    <div className="float-right">Jan 2009 to Present</div>
    <h2>{`WolfyMaster Media`}</h2>
    <h5>{`Freelance / Consulting`}</h5>
    <hr></hr>
    <p>{`Through automation and cloud technologies I can save businesses hundreds of dollars per month.
I work with business owners and other steakholders to identify business processes that are bottlenecks to performance or scalability.
Heavy AWS usage, serverless and cloud native technologies, API development, Golang, Nodejs, React, React-Native, Docker, Ansible`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      